function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import utils from './utils';
import Thumbnails from './thumbnails';
import Icons from './icons';
var Controller = function () {
    function Controller(player) {
        var _this = this;
        _classCallCheck(this, Controller);
        this.player = player;
        this.autoHideTimer = 0;
        if (!utils.isMobile) {
            this.player.container.addEventListener('mousemove', function () {
                _this.setAutoHide();
            });
            this.player.container.addEventListener('click', function () {
                _this.setAutoHide();
            });
            this.player.on('play', function () {
                _this.setAutoHide();
            });
            this.player.on('pause', function () {
                _this.setAutoHide();
            });
        }
        this.initPlayButton();
        this.initThumbnails();
        this.initPlayedBar();
        this.initFullButton();
        this.initQualityButton();
        this.initScreenshotButton();
        this.initSubtitleButton();
        this.initHighlights();
        if (utils.isSafari) {
            this.initAirplayButton();
        }
        if (!utils.isMobile) {
            this.initVolumeButton();
        }
    }
    _createClass(Controller, [
        {
            key: 'initPlayButton',
            value: function initPlayButton() {
                var _this2 = this;
                this.player.template.playButton.addEventListener('click', function () {
                    _this2.player.toggle();
                });
                this.player.template.mobilePlayButton.addEventListener('click', function () {
                    _this2.player.toggle();
                });
                if (!utils.isMobile) {
                    this.player.template.videoWrap.addEventListener('click', function () {
                        _this2.player.toggle();
                    });
                    this.player.template.controllerMask.addEventListener('click', function () {
                        _this2.player.toggle();
                    });
                } else {
                    this.player.template.videoWrap.addEventListener('click', function () {
                        _this2.toggle();
                    });
                    this.player.template.controllerMask.addEventListener('click', function () {
                        _this2.toggle();
                    });
                }
            }
        },
        {
            key: 'initHighlights',
            value: function initHighlights() {
                var _this3 = this;
                this.player.on('durationchange', function () {
                    if (_this3.player.video.duration !== 1 && _this3.player.video.duration !== Infinity) {
                        if (_this3.player.options.highlight) {
                            var highlights = document.querySelectorAll('.dplayer-highlight');
                            [].slice.call(highlights, 0).forEach(function (item) {
                                _this3.player.template.playedBarWrap.removeChild(item);
                            });
                            for (var i = 0; i < _this3.player.options.highlight.length; i++) {
                                if (!_this3.player.options.highlight[i].text || !_this3.player.options.highlight[i].time) {
                                    continue;
                                }
                                var p = document.createElement('div');
                                p.classList.add('dplayer-highlight');
                                p.style.left = _this3.player.options.highlight[i].time / _this3.player.video.duration * 100 + '%';
                                p.innerHTML = '<span class="dplayer-highlight-text">' + _this3.player.options.highlight[i].text + '</span>';
                                _this3.player.template.playedBarWrap.insertBefore(p, _this3.player.template.playedBarTime);
                            }
                        }
                    }
                });
            }
        },
        {
            key: 'initThumbnails',
            value: function initThumbnails() {
                var _this4 = this;
                if (this.player.options.video.thumbnails) {
                    this.thumbnails = new Thumbnails({
                        container: this.player.template.barPreview,
                        barWidth: this.player.template.barWrap.offsetWidth,
                        url: this.player.options.video.thumbnails,
                        events: this.player.events
                    });
                    this.player.on('loadedmetadata', function () {
                        _this4.thumbnails.resize(160, _this4.player.video.videoHeight / _this4.player.video.videoWidth * 160, _this4.player.template.barWrap.offsetWidth);
                    });
                }
            }
        },
        {
            key: 'initPlayedBar',
            value: function initPlayedBar() {
                var _this5 = this;
                var thumbMove = function thumbMove(e) {
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this5.player.template.playedBarWrap)) / _this5.player.template.playedBarWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this5.player.bar.set('played', percentage, 'width');
                    _this5.player.template.ptime.innerHTML = utils.secondToTime(percentage * _this5.player.video.duration);
                };
                var thumbUp = function thumbUp(e) {
                    document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
                    document.removeEventListener(utils.nameMap.dragMove, thumbMove);
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this5.player.template.playedBarWrap)) / _this5.player.template.playedBarWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this5.player.bar.set('played', percentage, 'width');
                    _this5.player.seek(_this5.player.bar.get('played') * _this5.player.video.duration);
                    _this5.player.timer.enable('progress');
                };
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragStart, function () {
                    _this5.player.timer.disable('progress');
                    document.addEventListener(utils.nameMap.dragMove, thumbMove);
                    document.addEventListener(utils.nameMap.dragEnd, thumbUp);
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragMove, function (e) {
                    if (_this5.player.video.duration) {
                        var px = _this5.player.template.playedBarWrap.getBoundingClientRect().left;
                        var tx = (e.clientX || e.changedTouches[0].clientX) - px;
                        if (tx < 0 || tx > _this5.player.template.playedBarWrap.offsetWidth) {
                            return;
                        }
                        var time = _this5.player.video.duration * (tx / _this5.player.template.playedBarWrap.offsetWidth);
                        if (utils.isMobile) {
                            _this5.thumbnails && _this5.thumbnails.show();
                        }
                        _this5.thumbnails && _this5.thumbnails.move(tx);
                        _this5.player.template.playedBarTime.style.left = ''.concat(tx - (time >= 3600 ? 25 : 20), 'px');
                        _this5.player.template.playedBarTime.innerText = utils.secondToTime(time);
                        _this5.player.template.playedBarTime.classList.remove('hidden');
                    }
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragEnd, function () {
                    if (utils.isMobile) {
                        _this5.thumbnails && _this5.thumbnails.hide();
                    }
                });
                if (!utils.isMobile) {
                    this.player.template.playedBarWrap.addEventListener('mouseenter', function () {
                        if (_this5.player.video.duration) {
                            _this5.thumbnails && _this5.thumbnails.show();
                            _this5.player.template.playedBarTime.classList.remove('hidden');
                        }
                    });
                    this.player.template.playedBarWrap.addEventListener('mouseleave', function () {
                        if (_this5.player.video.duration) {
                            _this5.thumbnails && _this5.thumbnails.hide();
                            _this5.player.template.playedBarTime.classList.add('hidden');
                        }
                    });
                }
            }
        },
        {
            key: 'initFullButton',
            value: function initFullButton() {
                var _this6 = this;
                this.player.template.browserFullButton.addEventListener('click', function () {
                    _this6.player.fullScreen.toggle('browser');
                });
                this.player.template.webFullButton.addEventListener('click', function () {
                    _this6.player.fullScreen.toggle('web');
                });
            }
        },
        {
            key: 'initVolumeButton',
            value: function initVolumeButton() {
                var _this7 = this;
                var vWidth = 35;
                var volumeMove = function volumeMove(event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this7.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this7.player.volume(percentage);
                };
                var volumeUp = function volumeUp() {
                    document.removeEventListener(utils.nameMap.dragEnd, volumeUp);
                    document.removeEventListener(utils.nameMap.dragMove, volumeMove);
                    _this7.player.template.volumeButton.classList.remove('dplayer-volume-active');
                };
                this.player.template.volumeBarWrapWrap.addEventListener('click', function (event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this7.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this7.player.volume(percentage);
                });
                this.player.template.volumeBarWrapWrap.addEventListener(utils.nameMap.dragStart, function () {
                    document.addEventListener(utils.nameMap.dragMove, volumeMove);
                    document.addEventListener(utils.nameMap.dragEnd, volumeUp);
                    _this7.player.template.volumeButton.classList.add('dplayer-volume-active');
                });
                this.player.template.volumeButtonIcon.addEventListener('click', function () {
                    if (_this7.player.video.muted) {
                        _this7.player.video.muted = false;
                        _this7.player.switchVolumeIcon();
                        _this7.player.bar.set('volume', _this7.player.volume(), 'width');
                    } else {
                        _this7.player.video.muted = true;
                        _this7.player.template.volumeIcon.innerHTML = Icons.volumeOff;
                        _this7.player.bar.set('volume', 0, 'width');
                    }
                });
            }
        },
        {
            key: 'initQualityButton',
            value: function initQualityButton() {
                var _this8 = this;
                if (this.player.options.video.quality) {
                    this.player.template.qualityList.addEventListener('click', function (e) {
                        if (e.target.classList.contains('dplayer-quality-item')) {
                            _this8.player.switchQuality(e.target.dataset.index);
                        }
                    });
                }
            }
        },
        {
            key: 'initScreenshotButton',
            value: function initScreenshotButton() {
                var _this9 = this;
                if (this.player.options.screenshot) {
                    this.player.template.camareButton.addEventListener('click', function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = _this9.player.video.videoWidth;
                        canvas.height = _this9.player.video.videoHeight;
                        canvas.getContext('2d').drawImage(_this9.player.video, 0, 0, canvas.width, canvas.height);
                        var dataURL;
                        canvas.toBlob(function (blob) {
                            dataURL = URL.createObjectURL(blob);
                            var link = document.createElement('a');
                            link.href = dataURL;
                            link.download = 'DPlayer.png';
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(dataURL);
                        });
                        _this9.player.events.trigger('screenshot', dataURL);
                    });
                }
            }
        },
        {
            key: 'initAirplayButton',
            value: function initAirplayButton() {
                if (this.player.options.airplay) {
                    if (window.WebKitPlaybackTargetAvailabilityEvent) {
                        this.player.video.addEventListener('webkitplaybacktargetavailabilitychanged', function (event) {
                            switch (event.availability) {
                            case 'available':
                                this.template.airplayButton.disable = false;
                                break;
                            default:
                                this.template.airplayButton.disable = true;
                            }
                            this.template.airplayButton.addEventListener('click', function () {
                                this.video.webkitShowPlaybackTargetPicker();
                            }.bind(this));
                        }.bind(this.player));
                    } else {
                        this.player.template.airplayButton.style.display = 'none';
                    }
                }
            }
        },
        {
            key: 'initSubtitleButton',
            value: function initSubtitleButton() {
                var _this10 = this;
                if (this.player.options.subtitle) {
                    this.player.events.on('subtitle_show', function () {
                        _this10.player.template.subtitleButton.dataset.balloon = _this10.player.tran('Hide subtitle');
                        _this10.player.template.subtitleButtonInner.style.opacity = '';
                        _this10.player.user.set('subtitle', 1);
                    });
                    this.player.events.on('subtitle_hide', function () {
                        _this10.player.template.subtitleButton.dataset.balloon = _this10.player.tran('Show subtitle');
                        _this10.player.template.subtitleButtonInner.style.opacity = '0.4';
                        _this10.player.user.set('subtitle', 0);
                    });
                    this.player.template.subtitleButton.addEventListener('click', function () {
                        _this10.player.subtitle.toggle();
                    });
                }
            }
        },
        {
            key: 'setAutoHide',
            value: function setAutoHide() {
                var _this11 = this;
                this.show();
                clearTimeout(this.autoHideTimer);
                this.autoHideTimer = setTimeout(function () {
                    if (_this11.player.video.played.length && !_this11.player.paused && !_this11.disableAutoHide) {
                        _this11.hide();
                    }
                }, 3000);
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.container.classList.remove('dplayer-hide-controller');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.container.classList.add('dplayer-hide-controller');
                this.player.setting.hide();
                this.player.comment && this.player.comment.hide();
            }
        },
        {
            key: 'isShow',
            value: function isShow() {
                return !this.player.container.classList.contains('dplayer-hide-controller');
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.isShow()) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                clearTimeout(this.autoHideTimer);
            }
        }
    ]);
    return Controller;
}();
export default Controller;